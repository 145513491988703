/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_360demo = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {
		
		/*
		Demo: Button
		*/
		$(document).on('click','.js-bt-demo',function() {
			specific_360demo.show();
		});
		
		/*
		Demo: Button
		*/
		$(document).on('click','.js-demo-exit',function() {
			specific_360demo.hide();
		});
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	this.show = function(
	) {
		
		/*
		Show demo
		*/
		$('.demo-overlay').fadeIn(400);
		
		$('.js-demo-exit').animate({
			top:0
		},500);
		
		/*
		Load iFrame
		*/
		$('.js-demo-iframe').attr('src',globalUrl + '/tours/demo/' + _language + '/welcome');

	};

	this.hide = function() {
	
		/*
		Hide demo
		*/
		$('.demo-overlay').fadeOut(500);
		
		$('.js-demo-exit').animate({
			top:-100
		},500);
		
		/*
		Load iFrame
		*/
		$('.js-demo-iframe').attr('src','');
		
	};
		
}).apply(specific_360demo); 
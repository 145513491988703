/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2019
	24-07-2019
	- [CHANGED] trim() added, to be sure that the error message has no spaces
2018
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_formhandling = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	// none
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		this.formHandlingBefore = function(
			formname
		) {
			
			switch(formname) {
			
				default:
					
					// nothing
					
				break;
		
			}
			
		};
		
		this.formHandling = function(
			formaction,
			formname,
			data
		) {
				
			/**
			Error
			**/
			if(data.trim().substring(0,5) === 'Error') {
				
				/**
				Catch unusual PHP message
				**/
				errorMessage = data.split('§');
				
				/**
				fadeIn error message
				**/
				specific_interactions_formhandling.error(formname,errorMessage[1]);
				
				/**
				Cancel and reset the form 
				**/
				form.cancelForm(formaction);
				
			/**
			Success
			**/
			} else {
				
				switch(formname) {
				
					default:
						
						// data
						// $('html,body').scrollTop(0); don't scroll to top!
				
						$(formaction).html(data);
						processForm = 0;
						
					break;
					
				}
			
			}
			
		};
		
		/**
		Callback: Radiobutton
		**/
		this.radiobuttonHandeling = function(t) {
			
			/**
			Catch the name
			**/
			var _this_radiobutton 			= $(t).data('name');
			var _this_radiobutton_value 	= $("input[name*=" + _this_radiobutton + "]").val();
			
			/**
			Callback
			**/
			switch(_this_radiobutton) {
				
				default:
				break;
				
			}
			
			
		};
		
		/**
		Callback: Checkbox
		**/
		this.checkboxHandeling = function(t,onoff) {
			
			/**
			Catch the name
			**/
			var _this_checkbox = $(t).data('name');
			
			/**
			Callback
			**/
			switch(_this_checkbox) {
				
				default:
				break;
				
			}
			
		};
		
		/**
		Callback: Select
		**/
		this.selectHandeling = function(t) {
			
			/**
			Catch the name
			**/
			var _this_selectbox 			= $(t).attr('name');
			var _this_selectbox_selected 	= $(t).val();
			
			/**
			Callback
			**/
			switch(_this_selectbox) {
				
				default:
				break;
				
			}
			
		};
		
		/**
		Upload Document / Image callback specifics
		**/
		this.uploaderCallback = function(
			callback,
			id,
			action,
			data
		) {
			
			switch(callback) {
				
				default:
				break;
				
			}
			
		};
		
		/**
		Upload Document / Image callback specifics delete
		**/
		this.uploaderCallbackDelete = function(
			callback,
			id
		) {
			
			switch(callback) {
				
				default:
				break;
				
			}
			
		};
		
		/**
		Error
		**/
		this.error = function(i,errormessage) { 
		
			switch(i) {
				
				default:
					modal.open('error',i,errormessage);
				break;
				
			}
		
		};
		
}).apply(specific_interactions_formhandling); 
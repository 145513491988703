/*
Version 0.105

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	10-10-2018
	- [CHANGED] Something changed on the callback and language file
	- [CHANGED] 1mb chunk size added to the uploader
	05-10-2018
	- [BORN] This class is born
	
!Wishlist
	
	// none
 
*/

var form_documents = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none

	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function(
		isPopup
	) {
		
		/**
		Initialize: PLUPLOADer Document listeners on Document Ready
		**/
		$(isPopup).find('.js-upload-document').each(function() {
			
			var _id 		= $(this).attr('id');
			var _input 		= $(this).find('.form-input').attr('name');
			var _actionfile = $(this).data('actionfile');
			var _callback 	= $(this).data('callback');
			
			form_documents.initUploaderDocument(
				_id,
				_input,
				_actionfile,
				_callback,
				isPopup
			);
			
		});
		
		/**
		Listener: Delete button
		**/
		$(document).on('click','.js-button-delete-document',function() {
			
			var _callback 	= $(this).parent().parent().data('callback');
			var _id 		= $(this).parent().parent().attr('id');
			
			form_documents.documentDelete(
				_callback,
				_id
			);
			
		});
		
	};
	
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	
	/**
	Upload *************************************************************************************
	Upload *************************************************************************************
	Upload *************************************************************************************
	Upload *************************************************************************************
	**/	
	this.initUploaderDocument = function(
		id,
		field,
		actionfile,
		callback,
		isPopup
	) {
	
		var uploader = new plupload.Uploader({
			
			runtimes : 'html5,html4',
			browse_button : id + '_upload_button',
			container: document.getElementById(id),
			url : globalUrl + '/uploads/' + actionfile,
			multiple_queues : false,
			multi_selection : false,
			max_file_count : 1,
			autostart: true,
			chunk_size : '1mb',
			
			filters : {
				max_file_size : '20mb',
				mime_types: [
					{	
						title:messages.upload_document_extension_title, 
						extensions:"pdf,doc,docx,csv"
					},
				]
			},
		
			init: {
				
				PostInit: function() {
				},
		
				FilesAdded: function(up, files) {
					up.start();
				},
		
				UploadProgress: function(up,file) {
					
					isUploadProcess = 1;
					
					/**
					Callback progress
					**/
					if(callback !== 0) {
						specific_interactions_formhandling.uploaderCallback(callback,id,'progress',file.percent);
					} else {
						$('#' + id + ' .upload-process').fadeIn(200);
						$('#' + id + ' .upload-process').html('<span>' + file.percent + '</span>%');
					}
					
				},
				
				FileUploaded: function(up,file,r) {	
				
					isUploadProcess = 0;
					
					/**
					Callback progress
					**/
					if(callback !== 0) {
						
						specific_interactions_formhandling.uploaderCallback(
							callback,
							id,
							'uploaded',
							r.response.trim()
						);
						
					} else {
						$('#' + id + ' .upload-info span').html(messages.upload_document_added);
						$('#' + id + ' .upload-process').fadeOut(200);
					}
					
					/**
					Set file
					**/
					$('.form-input-' + field).val(r.response.trim());
					
					/**
					Change button text
					**/
					$('#' + id).find('.upload-button').val(messages.upload_document_other);
					
				},
		
				Error: function(up, err) {
					isUploadProcess = 0;
					//console.log("\nError #" + err.code + ": " + err.message);
					if(err.code == '-600') { 
						alert('Dit bestand is te groot. Probeer een ander bestand.');
					}
					if(err.code == '-601') { 
						alert('Dit bestand heeft een onjuist bestandsformaat.');
					} 
					
				}
				
			}
		});
	
		uploader.init();

	};
	
	/**
	Delete document *****************************************************************************
	Delete document *****************************************************************************
	Delete document *****************************************************************************
	Delete document *****************************************************************************
	**/
	this.documentDelete = function(
		callback,
		id
	) {
		
		var _filename = $('#' + id).find('.form-input').val();
		
		// delete file
		// delete file
		// delete file
		// delete file
		
		/**
		Empty value
		**/
		$('#' + id).find('.form-input').removeAttr('value');
		
		/**
		Change button text and hide delete button
		**/
		$('#' + id).find('.upload-button').val(messages.upload_document_choose);
		
		/**
		Hide delete button
		**/
		$('#' + id).find('.js-button-delete-document').hide();
		
		/**
		Callback
		**/
		specific_interactions_formhandling.uploaderCallbackDelete(
			callback,
			id
		);
		
		
	};
	
}).apply(form_documents); 
/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	01-03-2018
	- [ADDED] Added document.ready to onready.
	26-02-2018
	- [ADDED] Open en Close methods are extended with new function to prevent body scrolling when menu is open
2017
	30-11-2017
	- [CHANGE] .js-menu-button is now changed to .js-mobile-menu-button
	15-10-2017
	- [METHOD] Add and remove .active class to the js-menu-button.
	30-08-2017
	- [BORN] This class is born. Hooray!

!Usage
	specific_mobilemenu.onready();

	
!Wishlist

	none
	
*/
  
var specific_mobilemenu = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	this.onready = function() {
		
		$(document).ready(function() {
		
			/**
			Bind listeners
			**/
			$(document).on('click','.js-mobile-menu-button',function() {
				specific_mobilemenu.toggle();
			});
			
			$('.js-mobile-menu').stop().animate({'top':-($('.js-mobile-menu').height() + $(window).height())},0);
			
		});
				
	};
	
	this.toggle = function(closeDirectly) {
		
		closeDirectly = typeof closeDirectly !== 'undefined' ? closeDirectly : 0;
		
		if(closeDirectly === 1) {
			
			if(isMobileMenuOpen === 1) {
				specific_mobilemenu.close();
			}
			
		} else {
			
			if(isMobileMenuOpen === 0) {
				specific_mobilemenu.open();
			} else {
				specific_mobilemenu.close();
			}
		
		}
		
  		
	};
	
	this.open = function() {
		
		var scrolledNow = $(window).scrollTop();
		
		mobileMenuSetScrolled = scrolledNow;
		
		$('.js-mobile-menu').stop().animate({'top':0},200,function() {
		
			/**
			Hide scroll on body
			**/
			$('body').addClass('no-scroll');
			
		});
		
		/**
		Add .active to the menu-button
		**/
		$('.js-mobile-menu-button').addClass('active');
		
		/**
		Set the variable
		**/
		isMobileMenuOpen = 1;	
	};
	
	this.close = function() {
		
		/**
		Hide the menu on top
		**/
		$('.js-mobile-menu').stop().animate({'top':-($('.js-mobile-menu').height() + $(window).height())},200,function() {
			
			/**
			Hide scroll on body
			**/
			$('body').removeClass('no-scroll');
			
		});
		
		/**
		Scroll the menu back to top
		**/
		$('.js-mobile-menu .scroller').stop().animate({ scrollTop: 0 }, "fast");
		
		/**
		Remove .active to the menu-button
		**/
		$('.js-mobile-menu-button').removeClass('active');
		
		/**
		Set the variable
		**/
		isMobileMenuOpen = 0;
		
	};
		
}).apply(specific_mobilemenu); 
/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_init = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Unbind: Unnecessary listeners
		**/
		//$(document).off('click.cookieWallSubmit');
		//$(document).off('click.cookieWallTabs');
		//$(document).off('click.cookieBarSubmit');
		
		/*
		Close the mobilemenu always on route
		*/
		specific_mobilemenu.toggle(1);
		
		/*
		Trigger: Instagram
		*/
		//specific_instagram.onready('thumb',8,true);

		/*
		Trigger: Facebook
		*/
		//specific_facebook.onready(6,true);
		
		/*
		Toggle menu
		*/
		$('.js-menu-button').removeClass('active');
		$('.js-menu-bckgrnd').removeClass('active');
		$('.js-menu-overlay').removeClass('active');

		/*
		Page marge
		*/
		$('.page-marge').animate({
			'margin-top':80,
		},500);
		
		/*
		Popup
		*/
		if(
			url[2] == 'interesse' 
		) {
			popup.open(1,1,'interesse');
		}
		if(
			url[2] == 'demo' 
		) {
			specific_360demo.show();
		}
		
		/*
		Slideshow: Clients
		*/
		var swiperClients = new Swiper('.js-slideshow-clients', {
		  slidesPerView: 3,
		  spaceBetween: 0,
		  speed: 4000,
		  autoplay: {
			delay: 1,
		  },
		  loop: true,
		  allowTouchMove: false,
		  disableOnInteraction: true
		});
		
		/*
		Slideshow: Cases
		*/
		var swiperCases = new Swiper('.js-slideshow-cases', {
		  slidesPerView: 'auto',
		  spaceBetween: 140,
		  speed: 10000,
		  autoplay: {
			delay: 1,
		  },
		  loop: true,
		  loopedSlides: 2
		});
		
		/*
		Main navigation: Get and set active button 
		Main navigation: Get and set active button 
		Main navigation: Get and set active button 
		Main navigation: Get and set active button 
		*/
		//$('.js-nav-item').removeClass('active');
						
		/*
		Catch URL
		*/
		//var _url_active_main = '';
		//
		//if(url[1] !== '') { 
		//	_url_active_main = url[1]; 
		//} else { 
		//	_url_active_main = './';
		//}
		//
		//if(_url_active_main !== '') {
		//	$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
		//}
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_init); 

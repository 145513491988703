/*
Version 0.145

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	27-05-2018
	- [CHANGE] Bugfix fade in background is now working when .no-fade is used.
	19-05-2018
	- [ADDED] onready now checks for device and automatically adds .no-blur to prevent blurring on mobile devices.
	22-04-2018
	- [CHANGE] Changed the vars to snakestyle ones.
	- [ADDED] New srcset is added to the funcionality; give extra srcset for different sizes.
	10-04-2018
	- [ADDED] Added new functionality to Blur Up the loaded images.
	13-03-2018
	- [CHANGE] Changed some things in the loader to speed stuff up.
	06-03-2018
	- [ADDED] Lazyload to only load images when they are inViewport.
	28-02-2018
	- [CHANGE] Changed the way of the preloader. Now new Image() is used here.
	23-02-2018
	- [CHANGE] SetTimeout is obsolete on loading the image, why was it there?
2017
	13-10-2017
	- [METHOD] Changed: the check of the object is div, span or img is now in the loading process.
	05-10-2017
	- [METHOD] Bugfix: Preload an <img> is repaired now.
	27-08-2017
	- [METHOD] Change: Preload is now async
	23-08-2017
	- [METHOD] Added: If object is an hyperlink (a) it was not there. Now it is. Also combined DIV,SPAN and A.
	- [BORN] This class is born. Hooray!

!Usage

	interactions.onready();

!Wishlist

	// Make srcset also working for background images, like: https://aclaes.com/responsive-background-images-with-srcset-and-sizes/

*/

var images = {};

(function(){

	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	/**
	None yet
	**/

	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************

	this.preload = function() {
		
		/**
		Check for mobile and add no-blur
		**/
		if(_device == 'mobile') {
			
			$('.go-preload-img').each(function() {
				
				if(!$(this).hasClass('no-blur')) {
					$(this).addClass('no-blur');
				}
			});
			
		}

		/**
		Catch all go-preload-img classes
		**/
		$('.go-preload-img').each(function() {

			/**
			Only load this when class is inViewport (Lazyload)
			**/
			if (inViewport(this) === true) {

				/**
				Declare local variables
				**/
				var fadeIn = 1;
				var blurUp = 1;

				var _this_obj = $(this);
				var _this_obj_is;
				var _image_file_src = '';
				var _image_file_sizes = '';
				var _image_file_srcset = '';
				var _image_preload = new Image();
				
				if(_this_obj.attr('data-go-src')) {
					_image_file_src = _this_obj.attr('data-go-src');
				}
				if(_this_obj.attr('data-go-sizes')) {
					_image_file_sizes = _this_obj.attr('data-go-sizes');
				}
				if(_this_obj.attr('data-go-srcset')) {
					_image_file_srcset = _this_obj.attr('data-go-srcset');
				}
				
				/**
				Has?
				**/
				if(_this_obj.hasClass('no-fade')) {
					fadeIn = 0;
				}
				if(_this_obj.hasClass('no-blur')) {
					blurUp = 0;
				}

				/**
				Load image when not undefined
				**/
				if(typeof _image_file_src != 'undefined') {

					_image_preload.src = _image_file_src;
					_image_preload.onload = function() {
						
						/**
						Check object is DIV, SPAN or IMG
						**/
						if(_this_obj.is('div')) {
							_this_obj_is = 'div';
						}
						if(_this_obj.is('span')) {
							_this_obj_is = 'span';
						}
						if(_this_obj.is('a')) {
							_this_obj_is = 'a';
						}
						if(_this_obj.is('img')) {
							_this_obj_is = 'img';

							/**
							Add .blur when blurUp is enabled
							**/
							if(blurUp == 1) {
								_this_obj.addClass('blur');
							}
						}

						/**
						DIV, SPAN or A
						**/
						if(
							_this_obj_is == 'div' ||
							_this_obj_is == 'span' ||
							_this_obj_is == 'a'
						) {

							_this_obj.animate({'opacity':0},100,function() {
								
								/**
								Set the new image URL
								**/
								_this_obj.css('background-image','url(' + _image_file_src + ')');

								/**
								Remove the go-preload-img class
								**/
								_this_obj.removeClass('go-preload-img');

								/**
								Animate the new image if fadeIn = default: 1
								**/
								if(fadeIn === 1) {
									_this_obj.stop().animate({'opacity':1},200);
								} else {
									_this_obj.stop().animate({'opacity':1},0);
								}

							});

						}

						/**
						IMG
						**/
						if(_this_obj_is == 'img') {
							
							if(fadeIn === 1) {

								/**
								Remove blur
								**/
								if(blurUp == 1) {

									/**
									Append the image and data-src if needed
									**/
									_this_obj.css({'opacity':0}).attr('src',_image_file_src);
									
									/**
									Append the attribute srcset
									**/
									if(_image_file_srcset !== '') {
										_this_obj.attr('srcset',_image_file_srcset);
									}
									
									_this_obj.stop().animate({'opacity':1},200,function() {
										_this_obj.addClass('lazyloaded');
									});

								} else {

									/**
									Append the image
									**/
									_this_obj.css({'opacity':0}).attr('src',_image_file_src);
									
									/**
									Append the attribute srcset
									**/
									if(_image_file_srcset !== '') {
										_this_obj.attr('srcset',_image_file_srcset);
									}
									
									_this_obj.stop().animate({'opacity':1},200);

								}

							} else {

								/**
								Remove blur
								**/
								if(blurUp == 1) {

									/**
									Append the image
									**/
									_this_obj.attr('src',_image_file_src);
									
									/**
									Append the attribute srcset
									**/
									if(_image_file_srcset !== '') {
										_this_obj.attr('srcset',_image_file_srcset);
									}
									
									_this_obj.addClass('lazyloaded');

								} else {

									/**
									Append the image
									**/
									_this_obj.attr('src',_image_file);
									
									/**
									Append the attribute srcset
									**/
									if(_image_file_srcset !== '') {
										_this_obj.attr('srcset',_image_file_srcset);
									}

								}

							}
							
							/**
							Add sizes as data-sizes when available
							**/
							if(_image_file_sizes !== '') {
								_this_obj.attr('data-sizes',_image_file_sizes);
							}
							
							/**
							Remove the go-preload-img class
							**/
							_this_obj.removeClass('go-preload-img');

						}

					};

				}

				/**
				Remove the data-go-src, data-go-srcset and data-go-sizes attribute
				**/
				_this_obj.removeAttr('data-go-src');
				_this_obj.removeAttr('data-go-sizes');
				_this_obj.removeAttr('data-go-srcset');

			}

		});

	};

}).apply(images);

/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	10-10-2018
	- [BORN] This file is born!

!Usage
	
	-
	
!Wishlist

	No wishes
 
*/
  
switch(_language) {
  
	/**
	Nederlands ********************************************
	Nederlands ********************************************
	Nederlands ********************************************
	Nederlands ********************************************
	**/
  	case 'nl':
		
		var messages = {
			
			/**
			Upload
			**/
			upload_document_none: 				'<span>Nog geen document toegevoegd</span>',
			upload_document_choose: 			'Kies een bestand',
			upload_document_other:				'Kies een ander bestand',
			upload_document_toobig:				'Dit bestand is te groot. Probeer een ander bestand.',
			upload_document_wrongextension:		'Dit bestand heeft een onjuist bestandsformaat.',
			upload_document_added:				'Bestand toegevoegd',
			upload_document_extension_title:	'Bestanden',
			
			upload_image_none: 					'<span>Nog geen foto toegevoegd</span>',
			upload_image_choose:				'Kies een afbeelding',
			upload_image_other:					'Kies een andere foto',
			upload_image_toobig:				'Dit bestand is te groot. Probeer een andere afbeelding.',
			upload_image_wrongextension:		'Dit bestand heeft een onjuist bestandsformaat. Probeer een afbeelding.',
			upload_image_added:					'Afbeelding toegevoegd',
			upload_image_extension_title:		'Afbeeldingen'
			
		};
		
	break;
	
	/**
	English ********************************************
	English ********************************************
	English ********************************************
	English ********************************************
	**/
	case 'en':
	
		// not yet here
	
	break;

}
/*
Version 0.135

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2019
	29-07-2019
	- [CHANGED] preserved_headers: false to fix the auto-rotate on camera of iphone.
	22-05-2019
	- [CHANGED] Some changes on image-upload.
	25-04-201
	- [CHANGE] Added some .off Jquery things otherwise delete was called multiple times.
	13-04-2019
	- [ADDED] Image Gallery functionalities.
2018
	10-10-2018
	- [CHANGED] Something changed on the callback and language file.
	05-10-2018
	- [BORN] This class is born.
	
!Wishlist
	
	// none
 
*/

var form_images = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none

	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function(
		isPopup
	) {
		
		/**
		Initialize: PLUPLOADer Image listeners on Document Ready
		**/
		$(isPopup).find('.js-upload-image').each(function() {
			
			var _id 		= $(this).attr('id');
			var _input 		= $(this).find('.form-input').attr('name');
			var _actionfile = $(this).data('actionfile');
			var _callback 	= $(this).data('callback');
			
			form_images.initUploaderImage(
				_id,
				_input,
				_actionfile,
				_callback,
				isPopup
			);
			
		});
		
		/**
		Initialize: PLUPLOADer Image listeners on Document Ready
		**/
		$(isPopup).find('.form-image-gallery').each(function() {
			
			var _input 		= $(this).find('.form-input').attr('name');
			
			form_images.initUploaderImageGallery(
				_input,
				isPopup
			);
			
		});
		
		/**
		Listener: Delete button
		**/
		$(document).off('click','.js-button-delete-image').on('click','.js-button-delete-image',function() {
			
			var _callback 	= $(this).parent().parent().data('callback');
			var _id 		= $(this).parent().parent().attr('id');
			
			form_images.imageDelete(
				_callback,
				_id
			);
			
		});
		
		/*
		Image gallery: delete image
		*/
		$(document).off('click','.js-image-gallery-image-delete').on('click','.js-image-gallery-image-delete',function() {
			
			_id 			= $(this).data('id').split('|');
			_gallery_field 	= _id[0];
			_image_id 		= _id[1];
			
			_file 			= $(this).data('file');
			_images 		= $('.form-input-' + _gallery_field).val();
			
			/**
			Prompt are you sure?
			**/
			if (window.confirm("Zeker weten?")) {
			
				/**
				Delete image from server
				**/
		
				/**
				Fire AJAX POST call
				**/
				$.ajax({
				  type: 'POST',
				  url: globalUrl + '/a/a/imagegallery/delete',
				  data: {
					  'image_file':_file,
				  },
				  success: function(data){
					  
					/**
					Catch notifier message
					**/
					notifierMessage = data.split(':');
					
					/**
					Show message on notifier
					**/
					if(data.substring(0,5) === 'Error') {
					
						/**
						Show error 
						**/
						specific_interactions.notifier(0,1,'Afbeelding error');
						
					} else {
						
						/**
						Show success!
						**/
						specific_interactions.notifier(0,0,'Afbeelding verwijderd');
					
					}
					
					/**
					Hide specific image
					**/
					$('.js-image-gallery-item_' + _gallery_field + '-' + _image_id).hide();
					
					/**
					Remove specific image from inputfield
					**/
					_imagesArray = _images.split(',');
					
					array_i = _imagesArray.indexOf(_file);
					if(array_i != -1) {
						_imagesArray.splice(array_i, 1);
					}
					
					$('.form-input-' + _gallery_field).val(_imagesArray);
					
				  }
				});
				
				return false;
				
			}
			
		});
		
	};
	
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	
	/**
	Upload *************************************************************************************
	Upload *************************************************************************************
	Upload *************************************************************************************
	Upload *************************************************************************************
	**/	
	this.initUploaderImage = function(
		id,
		field,
		actionfile,
		callback,
		isPopup
	) {
		
		/**
		Init Uploader
		**/
		var uploader = new plupload.Uploader({
			
			runtimes : 'html5,html4',
			browse_button : id + '_upload_button',
			container: document.getElementById(id),
			url : globalUrl + '/uploads/' + actionfile,
			multiple_queues : false,
			multi_selection : false,
			max_file_count : 1,
			autostart: true,
			chunk_size : '1mb',
			
			resize: {
				preserve_headers:false
			},
			
			filters : {
				max_file_size : '10mb',
				mime_types: [
					{
						title:messages.upload_image_extension_title,
						extensions:"jpg,png"
					},
				]
			},
		
			init: {
				
				PostInit: function() {
				},
		
				FilesAdded: function(up,files) {
					up.start();
				},
		
				UploadProgress: function(up,file) {
					
					isUploadProcess = 1;
					
					/**
					Callback progress
					**/
					if(callback !== 0) {
						
						specific_interactions_formhandling.uploaderCallback(
							callback,
							id,
							'progress',
							file.percent
						);
						
					} else {
						
						$('#' + id + ' .upload-process').fadeIn(200);
						$('#' + id + ' .upload-process').html('<span>' + file.percent + '</span>%');
						
					}
					
				},
				
				FileUploaded: function(up,file,r) {	
				
					isUploadProcess = 0;				
					
					/**
					Callback progress
					**/
					if(callback !== 0) {
						
						specific_interactions_formhandling.uploaderCallback(
							callback,
							id,
							'uploaded',
							r.response.trim()
						);
						
					} else {
						
						$('#' + id + ' .upload-info span').html(messages.upload_image_added);
						$('#' + id + ' .upload-process').fadeOut(200);
						
					}
					
					/**
					Change button text
					**/
					$('#' + id).find('.upload-button').val(messages.upload_image_other);
					
					/**
					Set value
					**/					
					$('.form-input-' + field).val(r.response.trim());
					
				},
		
				Error: function(up, err) {
					isUploadProcess = 0;
					//console.log("\nError #" + err.code + ": " + err.message);
					if(err.code == '-600') { 
						alert(messages.upload_image_toobig);
					}
					if(err.code == '-601') { 
						alert(messages.upload_image_wrongextension);
					} 
					
				}
				
			}
		});
	
		uploader.init();

		};
	
		/*
		Image Gallery: Upload init *****************************************************************************
		Image Gallery: Upload init *****************************************************************************
		Image Gallery: Upload init *****************************************************************************
		Image Gallery: Upload init *****************************************************************************
		*/
		this.initUploaderImageGallery = function(
			field,
			isPopup
		) {
			
			var uploader_image_gallery = new plupload.Uploader({
				
				runtimes : 'html5,html4',
				browse_button : 'uploader_imagegallery',
				container: document.getElementById('uploader-imagegallery'),
				url : globalUrl + '/statics/upload/image', // globalUrl + '/statics/upload' MOET ZO..
				multiple_queues : false,
				multi_selection : false,
				chunk_size:'1mb', 
				
				resize: {
					preserve_headers:false
				},
				
				max_file_count : 1,
				autostart: true,
				
				filters : {
					max_file_size : '5MB',
					mime_types: [
						{title : "Afbeeldingen", extensions : "jpg,png"},
					]
				},
			
				init: {
					
					PostInit: function() {
					},
			
					FilesAdded: function(up, files) {
						
						up.start();
					},
			
					UploadProgress: function(up, file) {
						
						isUploadProcess = 1;
						
						$('#uploader_imagegallery' + i + ' .upload-process').fadeIn(200);
						$('#uploader_imagegallery' + i + ' .upload-process').html('<span>' + file.percent + '</span>%');
					},
					
					FileUploaded: function(up,file,r) {
					
						isUploadProcess = 0;	
									
						//$('#uploader2' + i + ' .upload-info span').html('Bestand toegevoegd');
						$('#uploader-imagegallery .upload-process').fadeOut(200);
						//$('.form-input-' + field).val(r.response.trim());
						
						$('#uploader-imagegallery .no-images-yet').hide();
						
						/**
						Update input field with new image
						**/
						_images = $('.form-input-' + field).val();
						
						_images = _images + ',' + r.response.trim();
		
						
						if(_images.substr(1) == ',') { _images = _images.substr(1); }
						
						$('.form-input-' + field).val(_images);
						
						/**
						Add image to the rest
						**/
						_images_count = $('.image').size();
						
						$('#uploader-imagegallery .images').append(
						'<div class="js-image-gallery-item_' + field + '-' + _images_count + ' image" style="background-image:url(' + globalUrl + '/../img/uploads/' + r.response.trim() + ');">' + 
							'<div class="image-gallery-image-delete js-image-gallery-image-delete" data-id="' + field + '|' + i + '" data-file="' + r.response.trim() + '">' + 
							'<span class="icon icon-close"></span>' + 
							'</div>' + 
						'</div>'
						);
						
					},
			
					Error: function(up, err) {
						
						isUploadProcess = 0;
						
						//console.log("\nError #" + err.code + ": " + err.message);
						if(err.code == '-600') { 
							alert('Dit bestand is te groot. Probeer een andere afbeelding.');
						}
						if(err.code == '-601') { 
							alert('Dit bestand heeft een onjuist bestandsformaat. Probeer een JPG.');
						} 
						
					}
				}
			});
		
		uploader_image_gallery.init();
			
	};
			
	/**
	Delete image *****************************************************************************
	Delete image *****************************************************************************
	Delete image *****************************************************************************
	Delete image *****************************************************************************
	**/
	this.imageDelete = function(
		callback,
		id
	) {
		
		var _filename = $('#' + id).find('.form-input').val();
		
		// delete file
		// delete file
		// delete file
		// delete file
		
		/**
		Empty value
		**/
		$('#' + id).find('.form-input').removeAttr('value');
		
		/**
		Change button text
		**/
		$('#' + id).find('.upload-button').val(messages.upload_image_choose);
		
		/**
		Hide delete button
		**/
		$('#' + id).find('.js-button-delete-image').hide();
		
		/**
		Callback
		**/
		specific_interactions_formhandling.uploaderCallbackDelete(
			callback,
			id
		);
		
	};
	
}).apply(form_images); 
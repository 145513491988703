/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2019
	24-01-2018
	- [CHANGE] Changed the AJAX: POST call.
2018
	21-05-2018
	- [BORN] This class is born. Hooray!

!Usage

	just call the global functions
	
!Wishlist

	No wishes
 
*/

var cookiewall = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none

	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function() {
		
		/**
		Show first tab
		**/
		$('.js-cookiewall-tab-info').show();
		
		/**
		Hide hand-cursor on background
		**/
		$('.js-popup-background').css('cursor','default');
		
		/**
		Click on tabs
		**/
		$(document).on({
			mouseenter: function () {
				
				_get_option = $(this).data('option');
				
				/**
				Hide all
				**/
				$('.js-cookiewall-tab').hide();
				
				/**
				Show the one
				**/
				$('.js-cookiewall-tab-' + _get_option).show();
				
			},
			mouseleave: function () {
			}
		},".js-cookiewall-option");
		
		/**
		Click on options
		**/
		$(document).on('click.cookieWallOptions','.js-cookiewall-checkbox',function(e) {
			
			_get_option = $(this).attr('name');
			
			if(
				$(this).is(':checked') &&
				_get_option == 'all'
			) {
				
				/**
				Check all boxes
				**/
				$('.js-cookiewall-checkbox').prop('checked',true);
				
			}
			
		});
		
		/**
		Submit on CookieWall
		**/
		$(document).off('click.cookieWallSubmit').on('click.cookieWallSubmit','.js-cookiewall-submit',function(e) {
		
			_allow_needed 		= 0;
			_allow_statistics 	= 0;
			_allow_marketing	= 0;
			
			/**
			Catch checked values
			**/
			if($('.js-cookiewall-checkbox-needed').is(':checked') === true) {
				_allow_needed = 1;
			}
			
			if($('.js-cookiewall-checkbox-statistics').is(':checked') === true) {
				_allow_statistics = 1;
			}
			
			if($('.js-cookiewall-checkbox-marketing').is(':checked') === true) {
				_allow_marketing = 1;
			}
			
			/**
			Submit
			**/
			cookiewall.submit(
				_allow_needed,
				_allow_statistics,
				_allow_marketing
			);
			
		});
		
		/**
		Submit on CookieBar
		**/
		$(document).on('click.cookieBarSubmit','.js-cookiewall-bar-accept',function(e) {
					
			/**
			Submit
			**/
			cookiewall.submit(
				1,
				1,
				1
			);
			
			/**
			Hide bar
			**/
			$('.js-cookiewall-bar').animate({'bottom':-200},200);
		
		});
		
	};
	
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	
	this.open = function(i) {
		
		switch(i) {
			
			case 'wall':
				popup.open(1,1,'cookiewall',1);
			break;
			
			case 'bar':
				if(_cookiewall_settings[0] === 0) {
					$('.js-cookiewall-bar').animate({'bottom':0},200);
				}
			break;
		}
		
	};
	
	this.submit = function(
		allow_needed,
		allow_statistics,
		allow_marketing
	) {
		
		/**
		Form: Post
		**/
		if(_languagesCounter == 1) {
			_url = globalUrl + '/a/f/cookiewall';
		} else {
			_url = globalUrl + '/' + _language + '/a/f/cookiewall';
		}
		
		/*
		Ajax: POST
		*/
		$.ajax({
			url:_url,
			headers: { 'go-load':1 },
			type: 'post',
			data: {
				'needed'		:allow_needed,
				'statistics'	:allow_statistics,
				'marketing'		:allow_marketing
			},
		})
		.done(function(data) {
			
			/**
			Reload page
			**/
			if(_languagesCounter === 1) {
				_url_one = url[1];
			} else {
				_url_one = url[2];
			}
			
			if(_url_one == 'cookiewall') {
				window.location.href = globalUrl;
			} else {
				location.reload();
			}
			
		})
		.always(function() {
		});
		
	};
	
}).apply(cookiewall); 
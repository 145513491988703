/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_firstload = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Trigger: modal and popup preloader
		**/
		images.preload();
		modal.onready();
		popup.onready();
		form.onready();
		if(pollConnection === 1) { poll_connection.onready(); }
		
		/**
		Trigger: onready classes
		**/
		specific_mobilemenu.onready();
		specific_menubutton.onready();
		specific_360demo.onready();
		
		/*
		Popup: Language
		*/
		$(document).on('click','.js-bt-changelanguage',function() {
			popup.open(1,1,'language');
		});
		
		/*
		Button: Load more projects
		*/
		$(document).on('click','.js-button-loadmore',function() {
			
			$('.js-button-loadmore').hide();
			$('.js-load-more').show();
			
		});
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_firstload); 
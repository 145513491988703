/*
Version 0.105

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	26-04-2018
	- [ADDED] Multiple language now working with URL
	25-04-2018
	- [BORN] This class is born. Hooray!

!Usage

	poll_connection.onready();

!Wishlist

	none

*/

var poll_connection = {};

(function(){

	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	/**
	None yet
	**/

	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {
		
		var _delay = 5000; // 5 seconds
		
		poll_connection.pollNow(_delay);

	};

	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************

	this.pollNow = function(_delay) {

		/**
		URL
		**/
		if(_languagesCounter > 1) {	
			_url = globalUrl + '/' + _language + '/statics/heartbeat';
			} else {
			_url = globalUrl + '/statics/heartbeat';
		}
		
		/**
		Poll
		**/
		var poll_heartbeat = $.ajax({
			url:_url,
			timeout:1000
		})
		.done(function(data) {	
		
			if(data === 'alive') {
				$('.js-notifier-connection').animate({'bottom':-100},200);
			} else {
				$('.js-notifier-connection').animate({'bottom':20},200);
			}
				
				
		})
		.fail(function() {
			
			$('.js-notifier-connection').animate({'bottom':20},200);
			
		})
		.always(function() {
			
			setTimeout(function() {
				poll_connection.pollNow(_delay);
			},_delay);
			
		});
		
	};	
	
}).apply(poll_connection);

/*
Version 0.277

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2019
	11-07-2019
	- [CHANGE] Validation on Telephone is back.
	14-06-2019
	- [CHANGE] Validation on Telephone number is changed + break is gone; return is used.
	06-06-2019
	- [CHANGE] Changed the handeling of Checkbox.
	27-05-2019
	- [ADDED] Cancel form on submit form, so that it can be submitted again and again and again.
	12-05-2019
	- [CHANGED] Validate error on Popup now scrolls to field.
	13-04-2019
	- [ADDED] New feature added: specific_interactions_formhandling.formHandlingBefore(); for some handelings before form is processed.
2018
	30-10-2018
	- [CHANGED] cancelForm now also updates the Submit button
	05-10-2018
	- [ADDED] Seperate stuff from this to subclasses like: default_forms_images and default_forms_htmleditors;
	22-09-2018
	- [ADDED] TinyMCE Simple and Advanced now have extra styling CSS.
	19-09-2018
	- [ADDED] Zipcode (dutch) regex validation
	17-08-2018
	- [CHANGED] $(window) to $(document).
	- [ADDED] Callback added for Select
	27-07-2018
	- [ADDED] Validation for Dropdown added.
	- [ADDED] On click specific checkbox now has also an .off statement. Works better now, because of multiple trigger.
	10-07-2018
	- [ADDED] Callbacks added for Checkboxes and Radiobuttons
	03-07-2018
	- [CHANGED]	The Document upload now has also the callback options.
	- [ADDED]	Init for Document upload is now also working.
	15-06-2018
	- [FIX]		Bugfix: Dropdown validation gone wrong earlier.
	25-05-2018
	- [CHANGED] Bugfix: checkbox validation gone wrong earlier.
	13-05-2018
	- [CHANGED] TinyMCE Editor Advanced is now changed.
	07-03-2018
	- [CHANGED] The uploaders now can have 'actionfile' as value to preset the action_file and the callback_action;
				Two of the same uploaders can now work on the same page, have the same callback_action but a different action_file;
	- [CHANGED] Callback for the Uploaders now must have 'same' action different 'object'
	- [CHANGED] All methods now have the variables the same like 
				(
					var1,
					var2,
					etc.. 
				)
	06-03-2018
	- [CHANGED] Made the seperate three projects scripts back to one.
	28-02-2018
	- [ADDED] New variable checking if tinyMCE must be loaded or not
	- [ADDED] Preventing catch ENTER on textarea inputs, because of whiteline breaks
	05-02-2018
	- [ADDED] Chunksize enabled to 1mb on image uploader, because otherwise move_uploaded_file failure
	31-01-2018
	- [CHANGE] form.init() now checks if forms must be loaded in popup or in body.
	- [ADDED] Added callback_action to Document / Image PLUPLOADERS for specific.interactions.uploadCallback();
	29-01-2018
	- [ADDED] On behalf of Internet Explorer 11: baseUrl is set on Tiny MCE init
	17-01-2018
	- [CHANGED] Number field now only accepts 0-9 and sanitizes the input on-the-fly.
	- [ADDED] On submitting the form, now after the submit, the text on the specificsubmit and the submit button are going back to original.
	- [CHANGED] Changed: Some variables have now 'var' in front because they are local variables and not global
2017
	28-11-2017
	- [METHOD] Added: Url validation is now added
	- [METHOD] Added: .js-form-specificsubmit now working as alternative submitbutton. Used to trigger the 'real' submit button from outside the form.
	11-11-2017
	- [METHOD] Added: The TinyMCE init now first removes alle TinyMCE instances with tinymce.remove();
	03-11-2017
	- [METHOD] Added: new option for using 'htmleditor' and 'htmleditor_adv' element in forms. The init will check all the form element and load TinyMCE Editor.
	26-10-2017
	- [METHOD] Changed: Initializing the uploader functions is now changed to two separate functions: upload image and upload document.
	- [METHOD] Added: DateTimePicker validation option. It's always checking for 00-00-0000 00:00 input of the date and time for now.
	- [METHOD] Added: DateTimePicker option for using with the forms class.
	- [METHOD] Added: .form-input can nu also be number and be checked with is numeric function
	15-10-2017
	- [METHOD] Changed: .form-input is now changed to js-form-input for JS interaction.
	14-10-2017
	- [METHOD] Added: Datepicker now using language settings from the settings JS class. It's not dynamic yet.
	13-10-2017
	- [METHOD] Added: Submit form now checks for variable clearonsubmit and when this is 1 then on submit all values be reset (empty).
	12-10-2017
	- [CORE] Changed this all to class-JS protocol. Classname: form
	- [CORE] Changed: Submit by ENTER is now always trigger and checks the FORM if one is focussed.
	- [CORE] Changed: some classes now has .js- selector. More to come.
	- [CORE] Changed: formProcess is now changed to global variable isFormProcess.
	- [METHOD] Changed: Pressing enter when in Input field is now captured and triggers submit.
	10-10-2017
	- [METHOD] Added: isValidDate checks given date-notation from form-element to check if date is correct or not.
	08-10-2017
	- [METHOD] Changed: Behaviour of form handeling is now moved to specific_interactions.formHandeling();
	01-10-2017
	- [METHOD] Changed: Scrolling to the wrong input is now stopped, because it was not working the right way. field.focus still works.
	27-09-2017 
	- [METHOD] Validation: Password is now working like Normaltext
	30-08-2017 
	- [METHOD] Validation is now checking if Popup is open and then don't scroll to the input field
2016
	27-11-2016
	- [METHOD] Fix: validate form handling: all at once, now does't walk on every input field.
	- [METHOD] Fix: Edit on validateInput when normaltext is checked. Now it's from 1 character instead of 2.
	- [METHOD] Fix: set onselect validateInput to initDatepicker methods. Now it's working onselect.
	25-11-2016
	- [UPLOAD] Init changed: findInput find value is now on ID
	21-11-2016
	- [BIND] Changed 'keyup' to 'input' for 'oninput' call of the realtime validator function
	- [METHOD] validateForm upgraded with PHP error messages which action (PHP) will provide like 'Error:errormessagetext'
	10-10-2016
	- [BORN] This class is born
	
!Wishlist
	
	- Datepicker language must be dynamic loaded on setting like datepicker(nl);
	- When completed the submit of a form, then scroll to .thanks class in viewwindow.
	- min_len and max_len must be checked on 'normaltext' keyup. Is it rhe right count of chars? then is valid.
 
*/

var form = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none

	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function(
		i
	) {
		
		/** 
		Trigger Submit on ENTER button when cursor is in a input field
		**/		
		$(document).keypress(function(e) {
			
			var _tag = e.target.tagName.toLowerCase();
			
			/**
			Prevent ENTER on textarea because of breaks
			**/
			if(_tag != 'textarea')  {
			
				if(e.key == 'Enter') {
					_thisForm = $(e.target).closest("form").attr('id');
					
					if(typeof _thisForm != 'undefined' && isFormProcess === 0) {
						$('#' + _thisForm).find('.js-form-submit').trigger('click');
					}
					return false;
				}
			
			}
			
		});
		
		/**
		Call validation on dropdown
		**/
		$(document).on('change','.js-form-dropdown',function(e) {
			form.validateInput('change',$(this),'dropdown');
		});
		
		/**
		Call some function on keyup input
		**/
		$(document).on('input focus','input',function(e) {
			
			inputType = $(this).data('type');
			inputRequired = $(this).data('required');
			
			/**
			Only accept numbers on number field, prevent the rest
			**/
			if(inputType === 'number') {
				// Remove invalid characters
				var sanitized = $(this).val().replace(/[^-.0-9]/g, '');
				// Remove non-leading minus signs
				sanitized = sanitized.replace(/(.)-+/g, '$1');
				// Remove the first point if there is more than one
				sanitized = sanitized.replace(/\.(?=.*\.)/g, '');
				// Update value
				$(this).val(sanitized);
			}
			
			/** 
			Check all inputs on keyup, except checkbox and radiobutton
			**/
			if(inputType != 'checkbox' && inputType != 'radiobutton' && inputRequired === 1) {
				form.validateInput('onkeyup',$(this),inputType);
			}
			
		});
		
		/**
		Call some function on keypress textarea
		**/
		$(document).on('input focus','textarea',function(e) {
			
			inputType = $(this).data('type');
			inputRequired = $(this).data('required');
			
			if(inputRequired === 1) {
				form.validateInput('onkeyup',$(this),inputType);
			}
					
		});
		
		/** 
		Go validate the specific form on click
		**/
		$(document).on('click','.js-form-submit',function(e) {
			/**
			Only go on if isFormProcess = 0
			**/
			if(isFormProcess === 0) {
				formParent = $(this);
				form.validateForm(formParent);
			}	
		});
		
		/**
		Alternative Submitbutton
		**/
		$(document).on('click','.js-form-specificsubmit',function() {
			
			/**
			Catch form
			**/
			triggerFormSubmit = $(this).data('triggersubmit');
			
			/**
			Trigger submit
			**/
			$('#'+triggerFormSubmit).find('.js-form-submit').trigger('click');
		});
		
		/**
		If Radiobutton-specific is clicked, select the value
		**/
		$(document).on('click','.js-radiobutton-specific',function(e) {
			
			/**
			Catch the variables
			**/
			_correspondingValuename = $(this).data('name');
			_correspondingValue = $(this).data('value');
			
			/**
			Unselect all specific radiobuttons
			**/
			$('.js-radiobutton-specific-' + _correspondingValuename).removeClass('active');
			
			/**
			Clicked is active
			**/
			/**
			Unselect all specific radiobuttons
			**/
			$(this).addClass('active');
			
			/**
			Set the values
			**/
			$('.js-form-input-' + _correspondingValuename).val(_correspondingValue);
			
			/**
			Callback
			**/
			specific_interactions_formhandling.radiobuttonHandeling($(this));
			
		});
		
		/**
		If Checkbox-specific is clicked, select the value
		**/
		$(document).off('click','.js-checkbox-specific').on('click','.js-checkbox-specific',function(e) { // why this .off is needed, is not clear.
			
			/**
			Catch the variables
			**/
			_correspondingValuename = $(this).data('name');
			_correspondingValue = $(this).data('value');
			
			if($('.js-form-input-' + _correspondingValuename).val()) {
				
				/**
				Set the values
				**/
				$('.js-form-input-' + _correspondingValuename).val('');
				
				/**
				Remove active
				**/
				$(this).removeClass('active');
				
				/**
				Callback
				**/
				specific_interactions_formhandling.radiobuttonHandeling($(this),'off');
				
			} else {
				
				/**
				Set the values
				**/
				$('.js-form-input-' + _correspondingValuename).val(_correspondingValue);
				
				/**
				Add active
				**/
				$(this).addClass('active');
				
				/**
				Callback
				**/
				specific_interactions_formhandling.radiobuttonHandeling($(this),'no');
				
			}
			
		});
		
		/**
		Callbacks for checkboxes and radiobuttons
		**/
		$(document).on('change','.js-radiobutton-specific',function() {
			specific_interactions_formhandling.radiobuttonHandeling($(this));
		});
		
		$(document).on('click','.js-checkbox-specific',function() {
			specific_interactions_formhandling.checkboxHandeling($(this));
		});
		
		$(document).on('change','.js-form-dropdown',function() {
			specific_interactions_formhandling.selectHandeling($(this));
		});
		
		/**
		Trigger: forms init
		**/
		form.init(i);
		
	};
	
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	
	/**
	Initialize forms
	**/
	this.init = function(
		isPopup
	) {
		
		var _inside = '.page-loader';
		
		/**
		Check if init is on popup
		**/
		if(isPopup === 'popup') {
			_inside = '.js-popup-loader';
		} 
		
		/**
		Trigger: Subclasses
		**/
		form_images.onready(_inside);
		form_documents.onready(_inside);
		form_datepickers.onready(_inside);
		form_htmleditors.onready(_inside);
		
	};
	
	/**
	Validate form input *****************************************************************************
	Validate form input *****************************************************************************
	Validate form input *****************************************************************************
	Validate form input *****************************************************************************
	**/
	this.validateInput = function(
		submittype,
		field,
		inputType
	) {
		
		switch(inputType) {
			
			case 'token':
				if (field.val() && field.val().length == 32) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			// textfield or hidden
			case 'hidden':
			case 'normaltext':
			case 'password':
				if (field.val() && field.val().length >= 1) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			/**
			Zipcode
			**/
			case 'zipcode':
			
				var rege = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
				
				if (rege.test(field.val()) === true) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			// url
			case 'url':
			
				 var pattern = new RegExp(
					'^(https?:\\/\\/)?'+ // protocol
					'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
					'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
					'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
					'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
					'(\\#[-a-z\\d_]*)?$','i' // fragment locator
  				);
				
				if(!pattern.test(field.val())) {
				  return form.validateInputHandling(submittype,field,inputType,'error');
				} else {
				  return form.validateInputHandling(submittype,field,inputType,'ok');
				}
	  
			break;
			
			/**
			Dropdown
			**/
			case 'dropdown':
				
				/**
				Check for required
				**/
				if(field.attr('data-required') == 1) {
					
					if($("option:selected", field).data('validate') == 1) {
						return form.validateInputHandling(submittype,field,inputType,'ok');
					} else {
						return form.validateInputHandling(submittype,field,inputType,'error');
					}
					
				} else {

					return form.validateInputHandling(submittype,field,inputType,'ok');
					
				}
				
			break;
			
			/**
			Number
			**/
			case 'number':
			
				if (field.val() && !isNaN(parseInt(field.val()))) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
				
			break;
			
			/**
			Textarea
			**/
			case 'textarea':
				if (field.val() && field.val().length > 10) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			/**
			HTML Editor // TinyMCE
			**/
			case 'htmleditor_adv':
			
				if (field.val() && field.val().length > 10) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
				
			break;
			
			/**
			Date
			**/
			case 'date':
			case 'birthday':
			
				/**
				Check given date notation to validate
				**/
				if(field.data('valid')) {
					_validation = field.data('valid'); 
				} else {
					_validation = '';
				}
				
				/**
				Validate
				**/
				if (field.val() && isValidDate(field.val(),_validation) === true) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			/**
			DateTime
			**/
			case 'datetime':
			
				var _validation = '00-00-0000 00:00';
			
				var _dateCheck = false;
				var _timeCheck = false;
			
				var datetime = field.val();
				
				if(datetime) {
					
					/**
					Grab the notation
					**/		
					var date = datetime.substring(0,10);
					var time = datetime.substring(11,16);
					
					/**
					Check the date and time
					**/				
					if (isValidDate(date) === true) {
						_dateCheck = true;
					}
					if (isValidTime(time) === true) {
						_timeCheck = true;
					}
				
				}
				
				/**
				Return validation
				**/
				if(
					_dateCheck === true && 
					_timeCheck === true
				) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else {
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			
			break;
			
			/**
			Emailaddress
			**/
			case 'email':
			
				if (field.val() && isValidMail(field.val()) == 1) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
				
			break;
					
			/**
			Telephone
			**/
			case 'telephone':
			
				if (field.val() && isValidTelephone(field.val()) == 1) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
				
			break;
				
			/**
			Checkbox
			**/
			case 'checkbox':
			
				if (isChecked(field) === 1) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else {
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
				
			break;
			
			// radiobutton
			case 'radiobutton':
			break;
			
			// upload file
			case 'upload':
					
				if (isNull(field.val()) === false) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else {
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
				
			break;
				
		}
			
	};
	
	/**
	Input error or OK handling ********************************************************************************
	Input error or OK handling ********************************************************************************
	Input error or OK handling ********************************************************************************
	Input error or OK handling ********************************************************************************
	**/
	this.validateInputHandling = function(
		submittype,
		field,
		inputtype,
		status,
		errorhandling
	) {
		
		/**
		Handle OnKeyup or onSubmit
		**/
		if(submittype == 'onkeyup' || submittype == 'onsubmit') {
			
			if(submittype == 'onkeyup') {
				
				if(status == 'ok') {
					field.css({'background':formNormalColorBackground,'color':formNormalColorText});
					field.next('.indicator').addClass('ok');
				} else {
					field.next('.indicator').removeClass('ok');
				}
			
			} else if (submittype == 'onsubmit') {
				
				if(status == 'ok') {
					
					/* only when checkbox or upload */
					if(
						inputtype == 'checkbox' || 
						inputtype == 'upload'
					) {
						return false;
					}
					
					/* only when input or textarea */
					field.css({'background':formNormalColorBackground,'color':formNormalColorText});
					field.next('.indicator').addClass('ok');
					
				} else {
					
					/* only when checkbox or upload */
					if(
						inputtype == 'checkbox' || 
						inputtype == 'upload'
					) {
						alert(field.data('notice'));
						return false;
					}
					
					/* only when input or textarea */
					field.next('.indicator').removeClass('ok');
					field.css({'background-color':formErrorColorBackground,'color':formErrorColorText});
					
					if(form.formErrorHandling == 'allatonce') {
						
						//$('html,body').animate({ scrollTop: $(formAction).offset().top - 100 }, 200,function() {
						//});
						
					} else {
						
						/*
						Popup: No
						*/
						if(isPopupOpen === 0) {
					
							/* scroll to field and focus */	
							$('html,body').animate({ scrollTop: $(field).offset().top - 100 }, 200,function() {
								field.focus();
							});
							
						/*
						Popup: Yes
						*/
						} else {
							
							//$('.popup-scroller').animate({ scrollTop: $(field).offset().top }, 200,function() {
								field.focus();
							//});
							
						}
					
					}
					
					return false;
				}
				
			}
		
		/**
		Handle OnCount
		**/	
		} else if (submittype == 'count') {
			
			if(status == 'ok') {
				return true;
			} else {
				return false;
			}
		
		}
		
	};
	
	/**
	Validate form on submit **********************************************************************************
	Validate form on submit **********************************************************************************
	Validate form on submit **********************************************************************************
	Validate form on submit **********************************************************************************
	**/
	this.validateForm = function(
		i
	) {
		
		/**
		Get form, form-action and formname by ID
		**/
		var thisForm 				= i.parent().closest('.form');
		var thisFormAction 			= i.parent().closest('.form-action');
		var thisFormClearOnSubmit 	= thisForm.data('clearonsubmit');
		var thisFormErrorHandling 	= thisForm.data('errorhandling');
		var thisFormError 			= thisForm.data('errormessage');
		var thisFormName 			= thisForm.attr('id');
		
		/**
		Get and count all fields in form
		**/
		var countInputTotal 		= thisForm.find('.form-input').length;
		var countInputRequired 		= 0;
		var countInputTotalRequired = 0;
		
		/**
		Count and check all required fields
		**/
		for (i = 0; i < countInputTotal; i++) {
			if (thisForm.find('.form-input:eq('+i+')').data('required') == 1) {
			countInputTotalRequired++;
			}
		}
		
		/**
		When formErrorHandling is none. Just go without validation.
		**/
		if(thisFormErrorHandling != 'none') {
			
			/**
			Loop through fields and validate
			**/
			for (i = 0; i < countInputTotal; i++) {
				
				stopLoop = 0;
				
				inputType = thisForm.find('.form-input:eq('+i+')').data('type');
				
				// is required field?
				if(thisForm.find('.form-input:eq('+i+')').data('required') == 1) {
					
					// validate the input onsubmit
					form.validateInput('onsubmit',thisForm.find('.form-input:eq('+i+')'),inputType);
						
					// validate the input on count
					if(form.validateInput('count',thisForm.find('.form-input:eq('+i+')'),inputType) === true) {
						
						// input is validated OK
						countInputRequired++;
						
					} else {
						
						// not validated and check if error handling is onebyone
						if(thisFormErrorHandling == 'onebyone') {
							stopLoop = 1;
						}
						
					}
					
				}
				
				if(stopLoop == 1) {
					break;
				}
				
			}
			
		} else {
			
			countInputTotalRequired = countInputRequired;
			
		}
		
		/**
		If all required fields are validated OK then process form
		**/
		if(countInputRequired == countInputTotalRequired) {
			
			/**
			Form is validated, set processForm to 1
			**/
			isFormProcess = 1;
			
			/**
			Process interaction: change button text on submit buttons
			**/
			var _getSubmitText = thisForm.find('.js-form-submit').data('textonsubmit');
			thisForm.find('.js-form-submit').addClass('active').html(_getSubmitText);
			$(document).find('.submitbutton_' + thisFormName + '.js-form-specificsubmit').addClass('active');
			$(document).find('.submitbutton_' + thisFormName + '.js-form-specificsubmit').children('.label').html(_getSubmitText);
			
			/** 
			Set all inputs to read-only
			**/
			thisForm.find('.form-input').prop('readonly',true);
			thisForm.find('.form-input').css({'background-color':formReadOnlyColorBackground,'color':formReadOnlyColorText});
				
			/**
			Initialize TinyMCE
			**/
			countTinyMCE = $('.form-input-htmleditor_adv,.form-input-htmleditor,.form-input-htmleditor_simple').length;
			
			/**
			Are there instances to init?
			**/
			if(countTinyMCE >= 1) {
				
				/**
				TinyMCE Triggersave
				**/			
				tinymce.triggerSave();
			
			}
						
			/** 
			Get all values from the form
			**/
			var allValues = $(thisForm).serialize();
			
			/*
			Before processing
			*/
			specific_interactions_formhandling.formHandlingBefore(
				thisFormName
			);
			
			/**
			Get URL check on available languages
			**/
			var _url;
			if(_languagesCounter == 1) {
				_url = "/a/f/" + thisFormName;
			} else {
				_url = "/" + _language + "/a/f/" + thisFormName;
			}
			
			/** 
			Ajax: POST to action file
			**/					
			
			$.ajax({
				
				url:globalUrl + _url, 
				type:'POST',
				data:allValues,
				headers: { 
					'go-load':1 
				},
				success: function(data){
					
					/**
					Clear all values on submit
					**/
					if(thisFormClearOnSubmit === 1) {
						 $(thisForm).find('input[type=text],input[type=password],textarea').val('');
						 $(thisForm).find('select').find('option').prop("selected", false);
						 //$(thisForm).find('input[type=radio]').prop("checked", false);
					}
					
					/*
					Trigger: Formhandeling
					*/
					specific_interactions_formhandling.formHandling(
						thisFormAction,
						thisFormName,
						data
					);
					
					/*
					Cancel form after submit
					*/
					form.cancelForm(
						thisForm,
						thisFormName
					);
					
				}
			
			});
			
		} else {
			
			
		}
	
	};
	
	/**
	Cancel form ********************************************************************************
	Cancel form ********************************************************************************
	Cancel form ********************************************************************************
	Cancel form ********************************************************************************
	**/
	
	this.cancelForm = function(
		thisForm,
		thisFormName
	) {
		
		/**
		Form is not validated, set processForm to 0
		**/
		isFormProcess = 0;
		
		/** 
		Release all inputs that are readonly
		**/
		thisForm.find('.form-input').prop('readonly',false);
		thisForm.find('.form-input').css({'background-color':formNormalColorBackground,'color':formNormalColorText});
		
		/**
		Re-set Submit button
		**/
		thisForm.find('.js-form-submit').removeClass('active');
		_submit_button_text = thisForm.find('.js-form-submit').data('texttosubmit');
		thisForm.find('.js-form-submit').html(_submit_button_text);
			
	};

}).apply(form);
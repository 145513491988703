/*
Version 0.120

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2019
	14-05-2019
	- [ADDED] New function preventDefault();
2018
	18-07-2018
	- [ADDED] Added: isValidTime();
	06-03-2018
	- [CHANGED] Changed the inViewport method to a new one without an error.
2017
	10-10-2017
	- [HELPER] Added: new inViewport for checking element in viewport using pure JS.
	02-09-2017
	- [HELPER] Added: new function: makeInt: to make an int out of a string.
	30-03-2017
	- [HELPER] Added: new function: isOnscreen to check if an item is in viewport.
2016
	06-12-2016
	- [HELPER] Added: isEmpty to check a value is empty or undefined
	- [HELPER] Added: isURL to check a value is a url
	- [HELPER] Added: scorePassword to check a password is good enough
	04-12-2016
	- [HELPER] Added: calculateAge to calculate age by date in string format
	- [HELPER] Added: return todays date
	27-11-2016
	- [HELPER] isTelephone number changed to only checking if the number has 10 characters or more.
	04-11-2016
	- [FILENAME] Name has changed from validation_helpers.js to helpers.js
	- [BORN] This class is born. Hooray!

!Usage

	just call the global functions
	
!Wishlist

	No wishes
 
*/

/*
Date convertor
*/
function timeConverter(
	UNIX_timestamp
){
  
  var a 		= new Date(UNIX_timestamp * 1000);
  //var months 	= ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var months 	= ['01','02','03','04','05','06','07','08','09','10','11','12'];
  var year 		= a.getFullYear();
  var month 	= months[a.getMonth()];
  var date 		= a.getDate();
  var hour 		= a.getHours();
  var min 		= a.getMinutes();
  var sec 		= a.getSeconds();
  var time 		= date + '-' + month + '-' + year;
  
  return time;
  
  // ' + hour + ':' + min + ':' + sec ;
  
}

/*
Validate isEmpty
*/
function isEmpty(str) {
    if(str.length > 0) {
		return false;
	} else {
		return true;
	}
}

/**
 Validate isNull ****************************************************************
**/
function isNull(variable) {
	if (variable === '0') {
		return true;
	} else {
		return false;
	}	
}

/**
Make int from string  ****************************************************************
**/
function makeInt(str) {
	int = parseInt(str);
	return str;
}

/**
Int with comma
**/
function replaceDotWithComma(x) {
  return x.replace(".", ",");
}

/**
 Validate isString ****************************************************************
**/
function isString(str) {
	if (typeof str === 'string') {
		return 1;
	} else {
		return 0;
	}	
}

/**
Validate isNumeric 
**/
function isNumeric(n) {
}

/**
 Validate valid mailaddress ****************************************************************
**/
function isValidMail(str) {
	var emailFilter = /^[^@]+@[^@.]+\.[^@]*\w\w$/;
	
	if(!emailFilter.test(str)) { 
		return 0;
	} else {
		return 1;
	}	
}

/**
 Validate valid telephonenumber ****************************************************************
**/
function isValidTelephone(str) {
	
	if(str.length >= 10) { 
		return 1;
	} else {
		return 0;
	}	
}

/**
 Validate isDate ****************************************************************
**/
function isValidDate(value, userFormat) {

  // Set default format if format is not provided
  userFormat = userFormat || 'dd-mm-yyyy';

  // Find custom delimiter by excluding the
  // month, day and year characters
  var delimiter = /[^mdy]/.exec(userFormat)[0];

  // Create an array with month, day and year
  // so we know the format by index
  var theFormat = userFormat.split(delimiter);

  // Get the user date now that we know the delimiter
  var theDate = value.split(delimiter);

  function isDate(date, format) {
    var m, d, y, i = 0, len = format.length, f;
    for (i; i < len; i++) {
      f = format[i];
      if (/m/.test(f)) m = date[i];
      if (/d/.test(f)) d = date[i];
      if (/y/.test(f)) y = date[i];
    }
    return (
      m > 0 && m < 13 &&
      y && y.length === 4 &&
      d > 0 &&
      // Is it a valid day of the month?
      d <= (new Date(y, m, 0)).getDate()
    );
  }

  return isDate(theDate, theFormat);
  
}

/**
Validate time ****************************************************************
**/
function isValidTime(value) {

	  var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);

	  return isValid;

}

/**
 Validate checkbox is checked? ****************************************************************
**/
function isChecked(checkbox) {
	
	var oneIsChecked = $(checkbox).is(':checked');
	
	if (oneIsChecked === true) {
		return 1;
	} else {
		return 0;
	}
	
}

/**
Is element in viewport?
**/
function inViewport(element) {
	
	if($(element).length) {
			
		var elementTop = $(element).offset().top;
		var elementBottom = elementTop + $(element).outerHeight();
		
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();
		
		return elementBottom > viewportTop && elementTop < viewportBottom;
		
	}

}

/**
Return todays date in JS
**/
function showDateToday() {
	
	var today = new Date();
	var dd = today.getDate();
	var mm = today.getMonth()+1; //January is 0!
	var yyyy = today.getFullYear();
	
	if(dd<10) {
		dd='0'+dd;
	}
	
	if(mm<10) {
		mm='0'+mm;
	}
	
	today = dd + '-' + mm + '-' + yyyy;
	
	return today;
}

/**
Calculate age by date string
**/
function calculateAge(dateString) {
	var today = new Date();
	var birthDate = new Date(dateString);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	
	return age;
	
}

/**
Score a password on difficulty
**/
function scorePassword(pass) {
    var score = 0;
    if (!pass)
        return score;

    var letters = new Object({});
    for (var i=0; i<pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    };

    variationCount = 0;
    for (var check in variations) {
        variationCount += (variations[check] === true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    score = parseInt(score);
	
	return score;
}

/**
check or input is UrL
**/
function isUrl(s) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
}

/**
Check if .item (DIV) is onscreen
**/
$.fn.isOnScreen = function isOnScreen(){
    
    var win = $(window);
    
    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();
    
    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();
    
    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    
};
/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_menubutton = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/*
		Click
		*/
		$(document).on('click','.js-menu-button',function() {
			if($(this).hasClass('active') == true) {
				$(this).removeClass('active');
				$('.js-menu-overlay').removeClass('active');
				$('.js-menu-bckgrnd').removeClass('active');
			} else {
				$(this).addClass('active');
				$('.js-menu-overlay').addClass('active');
				$('.js-menu-bckgrnd').addClass('active');
			}
		});
		
		$(document).on('click','.js-menu-bckgrnd',function() {
			$('.js-menu-button').removeClass('active');
			$('.js-menu-overlay').removeClass('active');
			$('.js-menu-bckgrnd').removeClass('active');
		});
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_menubutton); 

/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2019
	13-04-2019
	- [CHANGE] Added new feature htmleditor_simple with some UI.
2018
	05-10-2018
	- [BORN] This class is born
	
!Wishlist
	
	// none
 
*/

var form_htmleditors = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none

	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function(
		isPopup
	) {
		
		/**
		Initialize htmleditors in form
		**/
		
		if(loadTinyMCE === 1) {
			form_htmleditors.initHTMLEditor(isPopup);
		}
		
	};
	
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	
	/**
	TinyMCE Editor function ********************************************************************************
	**/
	this.initHTMLEditor = function(
		popup
	) {
		
		/**
		Initialize TinyMCE
		**/
		countTinyMCE = $('.form-input-htmleditor_adv,.form-input-htmleditor,.form-input-htmleditor_simple').length;
		
		/**
		Are there instances to init?
		**/
		if(countTinyMCE >= 1) {
			
			/**
			Remove all TinyMCE instances
			**/
			tinymce.remove();
			
			/**
			Set: baseUrl 
			**/
			tinymce.baseURL 			= globalUrl + "/js/tinymce";
				
			/**
			No HTML options
			**/
			tinymce.init({
				selector: 				'.form-input-htmleditor',
				
				skin: 					'lightgray',
				
				plugins: [
					'paste'
				],
				toolbar: 				false,			// no toolbar
				
				menubar: 				false,			// no menubar
				elementpath: 			false,			// no elementpath
				
				branding: 				false,			// no branding
				remove_trailing_brs: 	true,
				force_br_newlines : 	true,
				forced_root_block : 	false, 			// don't make p blocks
				
				element_format : 		'html', 		// makes <br /> to <br>
				
				content_css: 			'./css/tinymce-simple.css', // content CSS
				
				init_instance_callback: function (editor) {
					editor.on('Change', function (e) {
						
						/**
						Validate here
						**/
						
					});
				}
				
			});
		
			/**
			With advanced HTML styling
			**/
			tinymce.init({
				selector: 				'.form-input-htmleditor_adv',
				skin: 					'lightgray',

				plugins: 				["table lists contextmenu paste code link charmap"],
				toolbar: 				'undo redo | bold italic underline | bullist numlist outdent indent | charmap link table | code',
				
				menubar: 				false,			// no menubar
				elementpath:			false,			// no elementpath
				
				branding: 				false,			// no branding
				remove_trailing_brs: 	true,
				force_br_newlines : 	true,
				forced_root_block : 	false, 			// don't make p blocks
				
				paste_as_text: 			true, 			// strip styling on paste
				element_format : 		'html', 		// makes <br /> to <br>
				
				content_css: 			'./css/tinymce-adv.css', // content CSS
				
				init_instance_callback: function (editor) {
					editor.on('Change', function (e) {
						
						/**
						Validate here
						**/
						
					});
				}
				
			});
			
			/**
			With simple HTML styling
			**/
			tinymce.init({
				selector: 				'.form-input-htmleditor_simple',
				skin: 					'lightgray',

				plugins: 				["lists paste code link charmap"],
				toolbar: 				'undo redo | bold italic underline | bullist numlist outdent indent',
				
				menubar: 				false,			// no menubar
				elementpath:			false,			// no elementpath
				
				branding: 				false,			// no branding
				remove_trailing_brs: 	true,
				force_br_newlines : 	true,
				forced_root_block : 	false, 			// don't make p blocks
				
				paste_as_text: 			true, 			// strip styling on paste
				element_format : 		'html', 		// makes <br /> to <br>
				
				content_css: 			'./css/tinymce-adv.css', // content CSS
				
				init_instance_callback: function (editor) {
					editor.on('Change', function (e) {
						
						/**
						Validate here
						**/
						
					});
				}
				
			});
		
		}
			
	};
	
}).apply(form_htmleditors); 